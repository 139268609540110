/* global modal */

(function ($) {
    'use strict';
    //here is an update for thumbnails and kaltura media
    $.widget('blueimp.fileupload', $.blueimp.fileupload, {
        $progress: null,
        $fileDiv: null,
        currentData: null,
        uploadTokenId: null,
        options: {
            id: null,
            serviceUrl: null,
            thumbnailUrl: null,
            ks: null,
            apiTimeout: 5000,
            paramName: 'fileData',
            autoUpload: false, // no effect
            kaltura: true,
            replaceFileInput: false
        },
        
        _onHandleProgress: function (e, data) {
            var progress = parseInt(data.loaded / data.total * 100, 10);
            this.$progress.css('width', progress + '%');
        },

        _onHandleAdd: function(e, data) {
            modal.hideSubmitButton();
            
            this.$fileDiv.find('.progress').removeClass('hidden');
            this.$fileDiv.find('.fileinput-button').hide();
            this._showAlert('upload-progress');
            this.currentData = data;
            
            if(this.options.kaltura){
                var params = {
                    'uploadToken:fileName': data.files[0].name
                };
                $.when(this._callKalturaApi('uploadToken', 'add', params))
                    .then($.proxy(this._handleUploadToken, this));
            }
            else{
                this.options.url = this.options.thumbnailUrl;
                this.currentData.process().done(function () {
                    this.currentData.submit();
                });
            }
        },
        isThumbnailFile: function(data){
            return data.response() && data.response().result && data.response().result.path;
        },
        //kaltura
        _handleUploadToken: function(data) {
            this.uploadTokenId = data.id;
            this.options.url = this._apiUrl('uploadToken', 'upload');
            this.options.url += '&uploadTokenId=' + this.uploadTokenId;
            this.options.url += '&ks='+this.options.ks;
            this.currentData.process().done(function () {
                this.currentData.submit();
            });
        },
        _onHandleDone: function(e, data) {
            if(this.isThumbnailFile(data)){
                this.uploadTokenId = data.response().result.path;
            }
            $('input[name="' + this.options.id + '"]').val(this.uploadTokenId);
            this._showAlert('upload-success');
            this.$fileDiv.find('.progress').hide();   
            modal.showSubmitButton();
        },
        _onHandleFail: function() {
            this._showAlert('upload-error');
        },
        //kaltura
        _callKalturaApi: function(service, action, params) {
            var url = this._apiUrl(service, action);
            params.ks = this.options.ks;
            params.format = 9;
            return $.ajax(url, {
                timeout: this.options.apiTimeout,
                data: params,
                dataType: 'jsonp'
            }).then(function(data) {
                var filter = $.Deferred();
                if (data.objectType == 'KalturaAPIException')
                    filter.reject(data.message, data.code);
                else
                    filter.resolve(data);
                return filter.promise();
            }, function() {
                var filter = $.Deferred();
                filter.reject('Generic error', -1);
                return filter.promise();
            }).fail($.proxy(this._genericErrorHandler, this));
        },

        _genericErrorHandler: function(msg, code) {
            this._showAlert('upload-error', '(Error code: ' + code + ')');
        },

        _apiUrl: function (service, action) {
            return this.options.serviceUrl + 'api_v3/index.php?service=' + service + '&action=' + action;
        },

        _showAlert: function(alert, info) {
            var $alerts = this.$fileDiv.find('.alerts');
            $alerts.find('.alert').addClass('hidden'); // hide all
            $alerts.find('.'+alert)
                .removeClass('hidden') // show what we need
                .find('small').text(info);
        },

        _create: function () {
            this._super();
            //overwriting functions
            this.element.bind(this.widgetEventPrefix + 'progress', $.proxy(this._onHandleProgress, this));
            this.element.bind(this.widgetEventPrefix + 'done', $.proxy(this._onHandleDone, this));
            this.element.bind(this.widgetEventPrefix + 'fail', $.proxy(this._onHandleFail, this));
            this.element.bind(this.widgetEventPrefix + 'add', $.proxy(this._onHandleAdd, this));

            this.$fileDiv = $('#' + this.options.id);
            this.$progress = this.$fileDiv.find('.progress-bar');
        }
    });

})(require('jquery'));
